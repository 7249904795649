import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import Blog from '../../src/Icons/Blog';
import BuyingGuide from '../../src/Icons/BuyingGuide';
import Compare from '../../src/Icons/Compare';
import Comparisons from '../../src/Icons/Comparisons';
import Competitors from '../../src/Icons/Competitors';
import Explore from '../../src/Icons/Explore';
import Faq from '../../src/Icons/Faq';
import Heart from '../../src/Icons/Heart';
import MattressMatcher from '../../src/Icons/MattressMatcher';
import Notes from '../../src/Icons/Notes';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Icon from '@mfrm/mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <Blog width={30} height={30} />\n  <BuyingGuide width={30} height={30} />\n  <Comparisons width={30} height={30} />\n  <Compare width={30} height={30} />\n  <Competitors width={30} height={30} />\n  <Explore width={30} height={30} />\n  <Heart width={30} height={30} />\n  <Faq width={30} height={30} />\n  <MattressMatcher width={30} height={30} />\n  <Notes width={30} height={30} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Blog,
      BuyingGuide,
      Compare,
      Comparisons,
      Competitors,
      Explore,
      Faq,
      Heart,
      MattressMatcher,
      Notes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Blog width={30} height={30} mdxType="Blog" />
    <BuyingGuide width={30} height={30} mdxType="BuyingGuide" />
    <Comparisons width={30} height={30} mdxType="Comparisons" />
    <Compare width={30} height={30} mdxType="Compare" />
    <Competitors width={30} height={30} mdxType="Competitors" />
    <Explore width={30} height={30} mdxType="Explore" />
    <Heart width={30} height={30} mdxType="Heart" />
    <Faq width={30} height={30} mdxType="Faq" />
    <MattressMatcher width={30} height={30} mdxType="MattressMatcher" />
    <Notes width={30} height={30} mdxType="Notes" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      